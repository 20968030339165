import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { StyledImg } from './index'

const ImageQuery = graphql`
  query genericImage {
    fate1: file(relativePath: { eq: "fate-ss-1.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fate2: file(relativePath: { eq: "fate-ss-2.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fate3: file(relativePath: { eq: "fate-ss-3.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fate4: file(relativePath: { eq: "fate-ss-4.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fate5: file(relativePath: { eq: "fate-ss-5.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fate6: file(relativePath: { eq: "fate-ss-6.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fateIcon: file(relativePath: { eq: "fate-icon.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    friendSS: file(relativePath: { eq: "fate-ss-friend.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chestSS: file(relativePath: { eq: "fate-ss-chest.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tonicSS: file(relativePath: { eq: "fate-ss-tonic.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fateHomeFramed: file(relativePath: { eq: "fate-ss-2_framed.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const Image = ({ imgName }) => (
  <StaticQuery
    query={ImageQuery}
    render={data => <StyledImg fixed={data[imgName].childImageSharp.fixed} />}
  />
)

export default Image

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
export * from './FateSplashImage'
export * from './FateIconImage'
export * from './FateMarketImage'
export * from './FateBattleImage'
export * from './FateHomeImage'
export * from './FateHomeImageFramed'
export * from './Image'

export const StyledImg = styled(Img)`
  margin: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
`
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

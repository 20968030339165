import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { StyledImg } from './index'

const ImageQuery = graphql`
  query iconImage {
    fateIcon: file(relativePath: { eq: "fate-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 125, maxHeight: 125) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const FateIconImage = () => (
  <StaticQuery
    query={ImageQuery}
    render={data => <StyledImg fluid={data.fateIcon.childImageSharp.fluid} />}
  />
)

export default FateIconImage

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { StyledImg } from './index'

const ImageQuery = graphql`
  query homeImageFramed {
    fateHomeFramed: file(relativePath: { eq: "fate-ss-2_framed.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const FateHomeImageFramed = () => (
  <StaticQuery
    query={ImageQuery}
    render={data => <Img fixed={data.fateHomeFramed.childImageSharp.fixed} />}
  />
)

export default FateHomeImageFramed

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { StyledImg } from './index'

const ImageQuery = graphql`
  query battleImage {
    fate4: file(relativePath: { eq: "fate-ss-4.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const FateBattleImage = () => (
  <StaticQuery
    query={ImageQuery}
    render={data => <StyledImg fixed={data.fate4.childImageSharp.fixed} />}
  />
)

export default FateBattleImage

import React from 'react'
import styled from 'styled-components'
import gpbadge from '../images/google-play-badge.png'
import iosBadge from '../images/ios-badge.svg'

const AppStores = styled.div`
  display: flex;
  align-items: center;
`

export const IOSBadge = () => (
  <a href="https://apps.apple.com/us/app/fate-a-game-of-chance/id1451790026?ls=1">
    <img
      style={{ width: '10rem' }}
      class="download-on-app-store"
      src={iosBadge}
      alt="download on the app store"
    />
  </a>
)

export const GPBadge = () => (
  <a href="https://play.google.com/store/apps/details?id=com.gameoffate.fate_a_game_of_chance">
    <img
      style={{ width: '11.5rem' }}
      class="download-on-app-store"
      src={gpbadge}
      alt="download on the app store"
    />
  </a>
)

export default AppStores

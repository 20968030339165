import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { FateIconImage } from '../components/image'

const NavBar = styled.nav`
  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const NavLink = styled(Link)`
  margin: 0 10px;
`

const Header = ({ siteTitle }) => (
  <div
    style={{
      background: `#365888`,
      // marginBottom: `1.45rem`,
    }}
  >
    <NavBar>
      <div style={{ width: '75px', height: '75px' }}>
        <Link to="/">
          <FateIconImage />
        </Link>
      </div>
      <p style={{ margin: 0 }}>
        <NavLink
          to="/roadmap"
          style={{
            color: `white`,
          }}
          activeStyle={{ color: '#E5B900' }}
        >
          ROADMAP
        </NavLink>
        <NavLink
          to="/mechanics"
          style={{
            color: `white`,
          }}
          activeStyle={{ color: '#E5B900' }}
        >
          MECHANICS
        </NavLink>
      </p>
    </NavBar>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import { lighten, darken } from 'polished'
import { createMuiTheme } from '@material-ui/core/styles'

export const appColors = {
  tonicDefault: 'aquamarine',
  tonicSelected: darken(0.37, 'aquamarine'),
  appText: '#231F20',
  appGrey: '#58595B',
  appGreyLight: lighten(0.37, '#58595B'),
  appGold: '#E5B900',
  appRed: '#B72525',
  appBlue: '#365888',
  appGreen: 'green',
  appOffWhite: '#ccc',
}

const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 750,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      superLight: '#7A7A7A',
      light: '#627686',
      main: '#374a59',
      dark: '#0e2230',
      visited: 'grey',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5F47B5',
      main: '#E64534',
      dark: '#100536',
      contrastText: '#FFF',
    },
    tertiary: '#64F7FF',
    accent: {
      orange: '#FB7451',
      yellow: '#F4B747',
      burnt: '#E64534',
      peach: '#F2A17D',
      red: '#CC443C',
      lightYellow: '#ffdca4',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

export default theme

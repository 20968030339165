import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import Theme from '../themes/default'
import { transparentize } from 'polished'
import { FateIconImage } from '../components/image'
import AppStores, { IOSBadge, GPBadge } from '../components/DownloadBadges'

const StyledFooter = styled.div`
  color: white;
  margin: 0 auto;
  padding-bottom: 4rem;
  padding-top: 1rem;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 0.75em;

  
  background-color: ${({ theme }) => theme.appGrey};
  /* background-color: ${transparentize(0.5, Theme.palette.primary.dark)}; */
`
const FooterLinks = styled.div`
  margin: 1em auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ExternalLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
`

const FooterLink = styled(Link)`
  /* text-decoration: none; */
  margin: 1em;
  /* font-size: 20px; */
  color: ${({ theme }) => theme.appGreyLight};
  &:visited {
    color: ${({ theme }) => theme.appGreyLight};
  }
  &:focus {
    color: ${({ theme }) => theme.appRed};
  }
  &:hover {
    color: ${({ theme }) => theme.appRed};
  }
`

class Footer extends React.Component {
  render() {
    return (
      <StyledFooter>
        <Link to="/">
          <div style={{ width: '125px' }}>
            <FateIconImage />
          </div>
        </Link>
        <h2> Fate, A Game of Chance</h2>
        <p>RULE THE REALM</p>
        <AppStores>
          <IOSBadge />
          <GPBadge />
        </AppStores>
        <FooterLinks>
          <FooterLink to="/privacy_policy"> privacy policy</FooterLink>
          {'|'}
          <FooterLink to="/credits"> credits</FooterLink>
          {/* <FooterLink to="/rules"> rules</FooterLink> ·
          <FooterLink to="/about"> about RocketSweep</FooterLink> */}
        </FooterLinks>
        <p>
          FATE © {new Date().getFullYear()}
          {` | `}
          {'Built by '}
          <ExternalLink href="https://twitter.com/grvangotten">
            @Garrett
          </ExternalLink>
          {' with '}
          <ExternalLink href="https://www.gatsbyjs.org">Gatsby</ExternalLink>
        </p>
      </StyledFooter>
    )
  }
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Footer from './footer'
import { ThemeProvider } from 'styled-components'
import defaultMuiTheme, { appColors } from '../themes/default'

import Header from './header'
import './layout.css'

const Layout = ({ children, color }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={appColors}>
        <React.Fragment>
          <Header />
          <div
            style={{
              margin: `0`,
              minHeight: '100%',
              paddingTop: 0,
              backgroundColor: color || appColors.appBlue,
              color: appColors.appOffWhite,
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            {children}
            <Footer />
          </div>
        </React.Fragment>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
